import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Carousel from "react-elastic-carousel";
import Item from "../components/Item";
//import Headerslider from '../components/Slider';
var s1 = require("../images/Header_slider.jpg").default;

const breakPoints = [
    { width: 1, itemsToShow: 1 }
]

const test = () => {
    return (
        <div className="App">
            <Carousel breakPoints={breakPoints}>
                <Item>
                    Test Page
                </Item>
                <Item>two</Item>
                <Item>three</Item>
            </Carousel>
            <div class="blue-box ">
                <div class="container">
                    <div class="row p-3">
                        <div class="col-md-8">
                            <h4 class="text-align-left text-color-white">Είναι και για τη δική μου επιχείρηση;</h4>
                            <h4 class="text-align-left text-color-white">Κλείσε ραντεβού με έναν σύμβουλο της Εθνικής τράπεζας</h4>
                        </div>
                        <div class="col-md-4 btn-holder">
                            <a href="./contact">
                                <div class="btn-book">
                                    Book Now
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
export default test;

