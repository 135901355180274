import React from 'react';
import Footer from '../components/Footer';
import Products from '../components/Allproducts';
import Header from '../components/Header';

function App() {
    return (
        <div className="App">
                <Products />
            <Footer />
        </div>
    );
}
export default App;