import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Carousel from "react-elastic-carousel";
import Item from "../components/Item";
//import Headerslider from '../components/Slider';
var s1 = require("../images/Header_slider.jpg").default;
var bg_boxed = require("../images/bg_bar.png").default;
var mil_sign = require("../images/Mhlonas_sign.png").default;
var mil_photo = require("../images/mhlonas_photo.png").default;
const breakPoints = [
    {width:1,itemsToShow:1}
]
const breakPoints2 = [
    { width: 1, itemsToShow: 3 }
]

function App() {
    return (
        <div className="App">
            <Carousel breakPoints={breakPoints}>
                <Item>
                    <img src={s1} alt="text" />
                </Item>
                <Item>two</Item>
                <Item>three</Item>
            </Carousel>
            <div class="blue-box ">
                <div class="container">
                    <div class="row p-3">
                        <div class="col-md-8">
                            <h4 class="text-align-left text-color-white">Είναι και για τη δική μου επιχείρηση;</h4>
                            <h4 class="text-align-left text-color-white">Κλείσε ραντεβού με έναν σύμβουλο της Εθνικής τράπεζας</h4>
                        </div>
                        <div class="col-md-4 btn-holder">
                            <a href="./contact">
                                <div class="btn-book">
                                        Book Now
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <div className="bg-center bg-cover boxed-bg" style={{ backgroundImage: `url(${bg_boxed})`}}>
                <div className="container">
                    <div className="title-container">
                        <h3>
                            Η Εθνική Τράπεζα οδηγεί την επιχείρησή σου στο μέλλον με προγράμματα στήριξης και χρηματοδότησης, πιο εύκολα, πιο γρήγορα και πιο αποτελεσματικά.
                        </h3>
                    </div>
                    <div className="row box-container">

                        <div className="col-12 col-sm-4 box-item">
                            <div className="box-item-holder">
                                <div className="box-row-title">
                                    Ελλάδα 2.0
                                </div>
                                <div className="box-row-text">
                                    <p>
                                        Τι είναι το Ταμείο ανάπτυξης και ποιους αφορά
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 box-item">
                            <div className="box-item-holder">
                                <div className="box-row-title">
                                    Εθνική 2.0
                                </div>
                                <div className="box-row-text">
                                    <p>
                                        Τι μπορεί να κάνει η Εθνική Τράπεζα για να λάβεις τη στήριξη που χρειάζεσαι πιο γρήγορα και πιο απλά.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 box-item">
                            <div className="box-item-holder">
                                <div className="box-row-title">
                                    Προγράμματα στήριξης
                                </div>
                                <div className="box-row-text">
                                    <p>
                                        Δες αναλυτικά τις προσκλήσεις που θα ανοίξουν για όλες τις επιχειρήσεις και βρες το πρόγραμμα που ταιριάζει στη δική σου.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="omilia">
                    <div className="row">
                        <div className="col-12 col-sm-6 text-col">
                            <div className="om-title">Η μεγάλη επιχειρηματική αναβάθμιση ξεκινάει εδώ</div>
                            <div className="om-text">Με τον όρο Lorem ipsum αναφέρονται τα κείμενα εκείνα τα οποία είναι ακατάληπτα, δεν μπορεί δηλαδή κάποιος να βγάλει κάποιο λογικό νόημα από αυτά, και έχουν δημιουργηθεί με σκοπό να παρουσιάσουν στον αναγνώστη μόνο τα γραφιστικά χαρακτηριστικά, αυτά καθ’ εαυτά, ενός κειμένου ή μιας οπτικής παρουσίασης και όχι να
Με τον όρο Lorem ipsum αναφέρονται τα κείμενα εκείνα τα οποία είναι ακατάληπτα, δεν μπορεί δηλαδή κάποιος να βγάλει κάποιο λογικό νόημα από αυτά, και έχουν δημιουργηθεί με σκοπό να παρουσιάσουν στον αναγνώστη μόνο τα γραφιστικά χαρακτηριστικά, αυτά καθ’ εαυτά, ενός κειμένου ή μιας οπτικής παρουσίασης και όχι να</div>
                            <div className="om-sign">
                                <img src={mil_sign} alt="milonas-kaligrafi" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 img-col">
                            <div className="om-image">
                                <img src={mil_photo} alt="milonas" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blue-box">
                <div className="container">
                    <Carousel breakPoints={breakPoints2}>
                        <Item>
                            <div className="p-3 car-item">
                                <img src={s1} alt="text" vurl="" vid="" />
                            </div>
                        </Item>
                        <Item>
                            <div className="p-3 car-item">
                                <img src={s1} alt="text" vurl="" vid="" />
                            </div>
                        </Item>
                        <Item>
                            <div className="p-3 car-item">
                                <img src={s1} alt="text" vurl="" vid="" />
                            </div>
                        </Item>
                        <Item>
                            <div className="p-3 car-item">
                                <img src={s1} alt="text" vurl="" vid="" />
                            </div>
                        </Item>
                    </Carousel>
                </div>
            </div>

            <Footer />
        </div>
    );
}
export default App;