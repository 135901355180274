import React from 'react';
const Product = (props) => {
    return (
        <div className="col-sm-4">
            <div className="card" style={{ width: "18rem" }}>
                <div className="card-body">
                    <h5 className="card-title">{props.product.title}</h5>
                </div>
            </div>
        </div>
    );
}
export default Product;