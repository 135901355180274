import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { HashRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

const client = new ApolloClient({
    uri: "https://api-eu-central-1.graphcms.com/v2/ckt04wq8i05yr01z3bu4ve9rw/master"
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <HashRouter>
            <App />
        </HashRouter>
    </ApolloProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();