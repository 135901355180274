import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Showproducts from '../pages/Show-products.js';
import Home from '../pages/home.js';
import Test from '../pages/test.js';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
    return (

        <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/Show-products" component={Showproducts}></Route>
            <Route path="/test" component={Test}></Route>
        </Switch>

    );

}
export default App;
