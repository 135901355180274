import React from 'react';
import { BrowserRouter as HashRouter,Router, Route, Switch, Link } from 'react-router-dom';

var logo = require("../images/logo.png").default;

const Navbar = () => {
    return (
        <div className="container">
            <nav className="row">
                <div className="col-12 col-md-4">
                    <Link to='/'><img src={logo} /></Link>
                </div>
                <div className="col-12 col-md-8 navbar-custom">
                    <Link to='/'><p>Home</p></Link>
                    <Link to='/Show-products'><p>Products</p></Link>
                    <Link to='/test'><p>Schedule</p></Link>
                </div>
            </nav>
        </div>
    );
};
export default Navbar;