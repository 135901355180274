import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from "./components/Navbar.js";
import Main from "./components/main.js"

function App() {
    return (
        
        <div className="App">

            <NavBar />
            <Main />

            
        </div>
    );

}
export default App;