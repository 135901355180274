import React from 'react';
import '../App.css';
import Navbar from './Navbar';
var logo = require("../images/logo.png").default;

const Header = () => {
    return (
        <section className="page-head font-small bg-blue pt-4">
            <div className="container">
            <div className=" row text-center text-md-left">
                <div className="col-md-4">
                    <img src={logo} height="100px" alt="test" />
                </div>
                <div className="col-md-8 mt-md-0 mt-3 centered-nav">
                    <Navbar />
                </div>
            </div>
            </div>
        </section>
    );
};
export default Header;