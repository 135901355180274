import React from 'react';
import '../App.css';
const Footer = () => {
    return (
        <footer className="page-footer font-small bg-blue pt-4">
            <div className="footer-copyright text-center py-3">� 2020 Copyright
          <span> Jnl+ </span>
            </div>
        </footer>
    );
};
export default Footer;